<i18n locale="th" lang="yaml" >
page.title : "สร้างใบซ่อมใหม่"
search.input.label : "S/N"
search.input.placeholder : "ระบุ S/N ของ Drone"
create.btn : "สร้างใบซ่อม"
</i18n>

<template>
	<div id="create_service_search_page" class="page page-padding page-fit-mobile">
		<my-page-header :title="$t('page.title')" />
		<div class="input-pane">
			<DroneSearchPanel ref="searchFilterRef" @submit-native-search="handleNativeSearch" />
		</div>
		<a-card v-if="isShowDroneTable" :bordered="false" >
			<div class="create-service-section">
				<a-button type="info" :disabled="!$notEmpty(selectedDroneSn)"
					@click="handleClickCreateService">
					{{$t('create.btn')}}
				</a-button>
			</div>
			<DroneTable
				bordered
				:show-pixhawk="true"
				:row-key="record => record.serialNo"
				:data-source="droneDataList"
				:pagination="pagination"
				:loading="loading"
				:hide-register="true"
				:hide-status="true"
				:hide-firmware="true"
				:hide-phone="false"
				:hide-email="false"
				:is-selectable="true"
				@change-drone="handleSelectDrone"
				@change="handleTableChangePage"/>
		</a-card>
	</div>
</template>

<script>
import axios from "axios"
import PageMixin from "@mixins/PageMixin.vue"
import ApiError from "@/src/utils/errors/ApiError"
import DroneSearchPanel from '@components/drone/DroneSearchPanel.vue'
import DroneTable from "@/src/components/drone/DroneTable.vue"
export default {
	components : {
		DroneSearchPanel,
		DroneTable
	},
	mixins : [PageMixin] ,
	page() {
		const pageTitle = this.$t('page.title')
		return {
			title : pageTitle
		}
	} ,
	data() {
		return {
			isShowDroneTable: false,
			droneDataList: [],
			pagination: {
				pageSize: 1000 ,
				current: 1,
				total: 0,
				hideOnSinglePage: true,
			},
			loading:false,
			selectedDroneSn: null
		}
	},
	methods : {
		handleClickCreateService() {
			if (this.$notEmpty(this.selectedDroneSn))
				this.$open({ name: 'drone/service_create', params: {serial_no: this.selectedDroneSn}, query: {from_create: true} });
		},
		callSearchDrone(toPage = null, keyword, searchType) {
			const filter = {
				keyword,
				searchType,
			}
			this.loading = true;
			axios.get("/api/drones/search",{params : { filter }}).then((response)=>{
				this.droneDataList = response.data.data.droneInstances;
				this.isShowDroneTable = true;
			}).catch((error) => {
				this.$message.error(ApiError.getDisplayErrorMessage(this,error))
			}).finally(()=>{
				this.loading = false
			})
		},
		handleNativeSearch(searchFilter) {
			this.selectedDroneSn = null;
			this.callSearchDrone(null, searchFilter.keyword, searchFilter.searchType);
		},
		handleTableChangePage(pagination, filters, sorter) {
			const searchFilter = this.$refs.searchFilterRef.getSearchFilter();
			this.callSearchDrone(pagination.current, searchFilter.keyword, searchFilter.searchType);
		},
		handleSelectDrone(droneSn){
			this.selectedDroneSn = droneSn;
		}
	}
}
</script>

<style lang="less" scoped>
.create-service-section{
	display: flex;
	justify-content: flex-end;
	margin-bottom: 15px;
}
.input-pane {
	padding : 12px 16px;
	border : 1px solid @white;
	border-radius: @border-radius-base;
	box-shadow : @shadow-1-right;
	background : @white;
	margin-bottom : 16px;
	> label {
		line-height: 32px;
		vertical-align: middle;
		margin-right : 2px;
		color : @info-color;
	}
}
</style>
